import Rows from '../../library/components/layout/Rows';
import Cell from '../../library/components/layout/Cell';
import Columns from '../../library/components/layout/Columns';
import DateTimeHelper from '../../library/helpers/DateTimeHelper';
import { CsmText } from '../../library/components/mui/CsmText';
import { CSSProperties } from 'react';
import appColors from '../../app/appColors';
import { Box, alpha } from '@mui/material';
import { useObserveElementWidthGroup } from '../../library/components/hooks/useObserveElementWidthGroup';
import { BusinessHelper } from '../../helpers/BusinessHelper';
import GenelDurumHelper from '../../helpers/GenelDurumHelper';
import DateTimeToStringHelper from '../../library/helpers/DateTimeToStringHelper';

export type IVatanCalendarProps = {
    renderDate: (day: Date, size: VatanCalendarSizes) => (React.ReactNode | undefined);
    isEtkinlikDay: (day: Date) => boolean;
    getDayCircleColor?: (day: Date) => string | undefined,
    onSelectedDataChanged: (day: Date) => void;
    seciliAy: Date,
    selectedDate: Date | undefined,
    isSecondCalendar?: boolean,
    style?: CSSProperties,
    bosGunSecilebilir: boolean,
    tatilleriGoster: boolean,
    geneldurumHelper: GenelDurumHelper,
}

export type VatanCalendarSizes = "none" | "small" | "medium" | "large";

function VatanCalendar(props: IVatanCalendarProps) {
    const borderBottom: CSSProperties = { borderBottom: "1px solid lightgray" };
    const borderRight: CSSProperties = { borderRight: "1px solid lightgray" };
    const startDateInit = DateTimeHelper.getMondayOfFirstWeekOfMonth(props.seciliAy);

    const { sizeName, ref } = useObserveElementWidthGroup<HTMLDivElement, VatanCalendarSizes>({ 1: "none", 500: "small", 650: "medium", 900: "large" });
    const weekCountForRegularTakvim = DateTimeHelper.getWeekCountOfMonth(props.seciliAy) + (sizeName === "small" ? 0 : 3);

    const specifics = {
        monthWidth: 45,
        dayNamesHeight: 25,
        cellHeight: 75,
        weekCount: weekCountForRegularTakvim,
        startDate: !props.isSecondCalendar ? startDateInit : DateTimeHelper.addDays(startDateInit, 7 * weekCountForRegularTakvim)
    } ;

    const { monthWidth, dayNamesHeight, cellHeight, weekCount, startDate } = specifics;

    const weeks: Date[][] = [];

    function determineSize<T>(v1: T, v2: T, v3: T): T {
        if (sizeName === "small")
            return v1;
        else if (sizeName === "medium")
            return v2;
        else
            return v3;
    }

    for (let i = 0; i < weekCount; i++) {
        const days: Date[] = []

        for (let j = 0; j < 7; j++)
            days.push(DateTimeHelper.addDays(startDate, i * 7 + j));

        weeks.push(days);
    }

    function GenerateDayNameCells() {
        return DateTimeToStringHelper.getNamesOfDays(props.geneldurumHelper.translate).map((gun, i) => {
            return (
                <Cell flex key={"gunisimler" + i} style={{ ...borderBottom, ...borderRight }}>
                    <CsmText note style={{ textAlign: "center", fontSize: determineSize("9px", "12px", "16px") }}>{gun}</CsmText>
                </Cell>
            );
        });
    }

    function GenerateRows() {
        return weeks.map((week, i) => {
            const aylar = week.select(e => DateTimeToStringHelper.toMonthString(e, props.geneldurumHelper.translate)).distinct();
            let aylarIcerik = aylar[0];

            if (aylar.length > 1) {
                aylarIcerik = `${aylar[0]} - ${aylar[1]}`;
            }

            const weekNumber = DateTimeHelper.getWeekNumberOfDate(week[0]).toFixed();

            return (
                <Columns height={cellHeight} key={"hafta" + i}>
                    <Cell width={monthWidth} style={{ ...borderBottom, ...borderRight, display: "flex" }}>
                        <Rows>
                            <CsmText small>{aylarIcerik}</CsmText>
                            <CsmText verySmall italic lightColor>{props.geneldurumHelper.translate("NTH Hafta", { weekNumber: weekNumber })}</CsmText>
                        </Rows>
                    </Cell>
                    {GenerateDays(week)}
                </Columns>
            );
        });
    }

    function onSelectedDataChanged(day: Date): void {
        //setSelectedDate(day);
        props.onSelectedDataChanged(day);
    }

    function getTodaySpan(day: Date): React.ReactNode {
        const tatilIsim = BusinessHelper.Genel.TatilIsimGetir(props.geneldurumHelper.SeciliKoroModel.tumTatiller, day);

        if (DateTimeHelper.isSameDate(day, DateTimeHelper.today()))
            return <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 12, color: appColors.PRIMARY_DARK }}>{props.geneldurumHelper.translate("Bugün")}</span>;
        else if (!!tatilIsim && day.getMonth() !== props.seciliAy.getMonth()) {
            return <>
                <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 12, color: appColors.LIGHTGRAY }}>{DateTimeToStringHelper.toMonthString(day, props.geneldurumHelper.translate)}</span>
                <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 8, color: appColors.LIGHTGRAY }}> - {tatilIsim}</span>
            </>;
        }
        else if (tatilIsim) {
            return <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 8, color: appColors.LIGHTGRAY }}>{tatilIsim}</span>;
        }
        else if (day.getMonth() !== props.seciliAy.getMonth())
            return <span style={{ display: determineSize("none", "inline", "inline"), fontSize: 12, color: appColors.LIGHTGRAY }}>{DateTimeToStringHelper.toMonthString(day, props.geneldurumHelper.translate)}</span>;
        else
            return null;
    }

    function GenerateDays(days: Date[]): React.ReactNode {
        return days.map((day, i) => {
            const addStyle: CSSProperties = {};
            const innerStyle: CSSProperties = {};
            const dateString = day.getDate().toString();

            const tatilIsim = BusinessHelper.Genel.TatilIsimGetir(props.geneldurumHelper.SeciliKoroModel.tumTatiller, day);

            const etkinlikGunuMu = props.isEtkinlikDay(day);
            if (tatilIsim) {
                addStyle.background = alpha(appColors.SECONDARY, 0.3);
            }
            else if (etkinlikGunuMu) {
                addStyle.background = alpha(appColors.GREEN_MAIN, 0.3)
            }
            else if (day.getMonth() !== props.seciliAy.getMonth())
                addStyle.background = alpha(appColors.GRAY_LIGHT4, 0.5);
            else if (DateTimeHelper.isSameDate(day, DateTimeHelper.today())) {
                addStyle.background = alpha(appColors.PRIMARY, 0.3);
            }

            if (DateTimeHelper.isSameDate(props.selectedDate ?? null, day)) {
                innerStyle.borderWidth = "1px";
                innerStyle.borderStyle = "solid";
                innerStyle.borderColor = appColors.PRIMARY_DARK;// "1px solid blue";
            }
            else
                innerStyle.borderColor = "transparent"; // innerStyle.border = "1px solid transparent";

            const content = props.renderDate(day, sizeName);
            const circleColor = props.getDayCircleColor ? props.getDayCircleColor!(day) : undefined;
            const circleSpan = !circleColor ? null : <span style={{
                height: "15px",
                width: "15px",
                backgroundColor: circleColor,
                borderRadius: "50%",
                display: "inline-block",
                position: "absolute",
                top: "3px",
                right: "3px",
            }}></span>

            if (content)
                return <Cell
                    dontGrowForChild
                    key={"gundolu" + i}
                    flex
                    style={{ display: "flex", ...borderBottom, ...borderRight, ...addStyle }}
                    hoverStyle={{ background: appColors.VERYLIGHTYELLOW }}
                    onClick={() => onSelectedDataChanged(day)}>
                    <div style={{ minWidth: "0px", flex: 1, ...innerStyle }}>
                        <Rows>
                            <Columns style={{ overflow: "hidden", position: "relative" }}>
                                <CsmText key="initgundolu" style={{ whiteSpace: "nowrap", fontSize: determineSize("12px", "14px", "14px"), paddingLeft: "4px", background: alpha(appColors.GRAY_LIGHT4, 0.5), width: "100%" }}>{dateString} {getTodaySpan(day)}</CsmText>
                                {circleSpan}
                            </Columns>
                            {content}
                        </Rows>
                    </div>
                </Cell >
            else
                return <Cell
                    dontGrowForChild
                    key={"gunbos" + i}
                    flex
                    style={{ ...borderBottom, ...borderRight, ...addStyle }}
                    hoverStyle={props.bosGunSecilebilir ? { background: appColors.VERYLIGHTYELLOW } : undefined}
                    onClick={props.bosGunSecilebilir ? (() => onSelectedDataChanged(day)) : undefined}>
                    <Rows>
                        <Columns style={{ overflow: "hidden", position: "relative" }}>
                            <CsmText key="initgunbos" style={{ whiteSpace: "nowrap", fontSize: "14px", paddingLeft: "4px", background: alpha(appColors.GRAY_LIGHT4, 0.5), width: "100%" }}>{dateString} {getTodaySpan(day)}</CsmText>
                            {circleSpan}
                        </Columns>
                    </Rows>
                </Cell>
        });
    }

    return (
        <Box ref={ref} visibility={sizeName === "none" ? "hidden" : undefined}>
            <Rows style={props.style}>
                <Columns height={dayNamesHeight}>
                    <Cell style={{ ...borderBottom, ...borderRight }} width={monthWidth}></Cell>
                    {GenerateDayNameCells()}
                </Columns>
                {GenerateRows()}
            </Rows>
        </Box>
    )
}



export default VatanCalendar;