/* eslint-disable react/no-children-prop */
// cihan
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../library/components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, CsmSwipableListItem } from "../../../library/components/mui/CsmSwipableListItem";
import { CsmText } from "../../../library/components/mui/CsmText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Box, Button, FormControl, List, ListItem, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../library/helpers/AlertHelper";
import CalismaPopupEditor from "./CalismaPopupEditor";
import { GuidHelper } from "../../../library/helpers/GuidHelper";
import DateTimeHelper from "../../../library/helpers/DateTimeHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import Columns from "../../../library/components/layout/Columns";
import Cell from "../../../library/components/layout/Cell";
import CsmTextField from "../../../library/components/mui/CsmTextField";
import CsmAccordionSimple from "../../../library/components/mui/CsmAccordionSimple";
import { CalismaModel } from "../../../models/businessModels/CalismaModel";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { VatanCalendarSizes } from "../../../components/vatan/VatanCalendar";
import VatanCalendarWithNavigation from "../../../components/vatan/VatanCalendarWithNavigation";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import appColors from "../../../app/appColors";
import DateTimeToStringHelper from "../../../library/helpers/DateTimeToStringHelper";

function CalismaYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <CalismaYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function CalismaYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");
  const [takvimGoster, setTakvimGoster] = useState<boolean>(true);
  const [altSezonlariGoster, setAltSezonlariGoster] = useState<boolean>(false);

  const yonetimYetkisi = genelDurumHelper.HasYetkiAll([EnmYetki["Calışma Yönetim"]]);
  const altSezonKullan = genelDurumHelper.SeciliKoroModel.koroAyarlari.altSezonlarKullanilsin;

  const calismaRowGetir = (calismaList: CalismaModel[], ayGrupla?: boolean): JSX.Element[] | null => {
    if (calismaList.length === 0)
      return null;

    if (ayGrupla !== true)
      return calismaRowGetirInner(calismaList);

    if (genelDurumHelper.SeciliKoroModel.koroAyarlari.etkinlikTakivimiKullanilsin) {
      const gunler = calismaList.select(e => DateTimeHelper.getDateWithoutTime(e.tarihBaslangic).valueOf()).distinct().select(e => new Date(e));

      return [<Rows key={DateTimeToStringHelper.toMonthDayAndDayOfWeekStringWithoutYear(gunler.first())}>
        {gunler.map(tarih => <Rows key={tarih.toDateString()}>
          <CsmSwipableListItem hasLightBottomBorder style={{ fontStyle: "italic", width: "490px" }}>
            <CsmText italic>{DateTimeToStringHelper.toMonthDayAndDayOfWeekStringWithoutYear(tarih)}</CsmText>
          </CsmSwipableListItem>
          <Columns wrap>
            {calismaRowGetirInner(calismaList.where(e => DateTimeHelper.getDateWithoutTime(e.tarihBaslangic).valueOf() === tarih.valueOf()))}
          </Columns>
        </Rows>
        )}
      </Rows>];
    }
    else {
      const ayBaslari = calismaList.select(e => e.tarihBaslangic).select(e => DateTimeHelper.getFirstOfMonth(e).valueOf()).distinct().select(e => new Date(e));

      return [<Rows key={DateTimeToStringHelper.toDDMMYYStringWithDot(ayBaslari.first())}>
        {ayBaslari.map(tarih => <Rows key={tarih.toDateString()}>
          <CsmSwipableListItem hasLightBottomBorder style={{ fontStyle: "italic", width: "490px" }}>
            <CsmText italic>{DateTimeToStringHelper.getMonthName(tarih.getMonth() + 1)} - {tarih.getFullYear()}</CsmText>
          </CsmSwipableListItem>
          <Columns wrap>
            {calismaRowGetirInner(calismaList.where(e => e.tarihBaslangic.getFullYear() === tarih.getFullYear() && e.tarihBaslangic.getMonth() === tarih.getMonth()))}
          </Columns>
        </Rows>
        )}
      </Rows>];
    }
  }

  const calismaRowGetirInner = (calismaList: CalismaModel[]): JSX.Element[] | null => {
    if (calismaList.length === 0)
      return null;

    return calismaList.map((item) => {
      let sesGrupIsim = BusinessHelper.SesGrubu.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumSesGruplari, item.sesGrupIdList);
      const kadroIsim = BusinessHelper.Kadro.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumKadrolar, item.kadroIdList, "Full");

      if (sesGrupIsim)
        sesGrupIsim = ` (${sesGrupIsim})`;

      const altBilgi1 = `Durum: Onaylı`;
      const altBilgi2 = `${genelDurumHelper.translate("Hedef Kitle")}: ${kadroIsim}${sesGrupIsim}`;
      const altBilgi3 = `Tarih: ${DateTimeToStringHelper.toMonthDayAndDayOfWeekString(item.tarihBaslangic, genelDurumHelper.translate)} / ${DateTimeToStringHelper.toTimeString(item.tarihBaslangic)} - ${DateTimeToStringHelper.toTimeString(item.tarihBitis)}`;

      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, text: genelDurumHelper.translate("Güncelle"), color: "primary", onClick: () => calismaGuncelle(item, yonetimYetkisi) });

      if (yonetimYetkisi) {
        rightButtons.push({ icon: appIcons.CrudIcons.kopyala, text: genelDurumHelper.translate("Kopyala"), color: "secondary", onClick: () => calismaKopyala(item) });
        rightButtons.push({ icon: appIcons.CrudIcons.sil, text: genelDurumHelper.translate("Sil"), color: "error", onClick: () => calismaSil(item) });
      }

      return (
        <CsmSwipableListItem hasLightBottomBorder leftButtons={rightButtons} key={item.id} style={{ width: "490px" }}>
          <Rows flex>
            <CsmText style={{ alignSelf: "flex-start" }}>{item.icerik}</CsmText>
            <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi1}</CsmText>
            <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi2}</CsmText>
            <CsmText style={{ alignSelf: "flex-start" }} note>{altBilgi3}</CsmText>
          </Rows>
        </CsmSwipableListItem>
      );
    });
  }

  const calismaKopyala = async (calismaModelOriginal: CalismaModel): Promise<void> => {
    const calismaModel = new CalismaModel();
    calismaModel.id = GuidHelper.NewGuid();
    calismaModel.icerik = calismaModelOriginal.icerik + " Kopyası";
    calismaModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
    calismaModel.kadroIdList = calismaModelOriginal.kadroIdList;
    calismaModel.sesGrupIdList = calismaModelOriginal.sesGrupIdList;
    calismaModel.sezonModelId = calismaModelOriginal.sezonModelId;
    calismaModel.etkinlikKonserMi = calismaModelOriginal.etkinlikKonserMi;
    calismaModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    calismaModel.olusturmaTarih = new Date();
    calismaModel.tarihBaslangic = calismaModelOriginal.tarihBaslangic;
    calismaModel.tarihBitis = calismaModelOriginal.tarihBitis;

    const response = await AlertHelper.ShowClosableContent<CalismaModel>(genelDurumHelper.translate("Çalışma Ekle"), (closer) => (
      <CalismaPopupEditor
        calismaModel={calismaModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const calismalar = seciliKoro.tumCalismalar;
        calismalar.push(response!);
      });
  }

  const calismaEkle = async (day: Date) => {
    const calismaModel = new CalismaModel();
    calismaModel.id = GuidHelper.NewGuid();
    calismaModel.icerik = "";
    calismaModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
    calismaModel.kadroIdList = [];
    calismaModel.sesGrupIdList = [];
    calismaModel.sezonModelId = genelDurumHelper.SeciliKoroModel.sezonModelId;
    calismaModel.etkinlikKonserMi = false;
    calismaModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    calismaModel.olusturmaTarih = new Date();
    calismaModel.tarihBaslangic = DateTimeHelper.addMinutes(day, 19 * 60 + 30);
    calismaModel.tarihBitis = DateTimeHelper.addMinutes(day, 21 * 60 + 30);

    const response = await AlertHelper.ShowClosableContent<CalismaModel>(genelDurumHelper.translate("Çalışma Ekle"), (closer) => (
      <CalismaPopupEditor
        calismaModel={calismaModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const calismalar = seciliKoro.tumCalismalar;
        calismalar.push(response!);
      });
  }

  const calismaSil = async (calismaModel: CalismaModel): Promise<void> => {
    if (await AlertHelper.Confirm(genelDurumHelper.translate("Dikkat"), genelDurumHelper.translate("Çalışmayı silmek istediğinizden emin misiniz?")) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Calisma.CalismaSil(calismaModel.id, "Çalışma siliniyor");
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const calismalar = seciliKoro.tumCalismalar;
        calismalar.remove(calismalar.single(e => e.id === calismaModel.id));
      });
  }

  const calismaGuncelle = async (calismaModel: CalismaModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<CalismaModel>(genelDurumHelper.translate("Çalışma Güncelle"), (closer) => (
      <CalismaPopupEditor
        guncellenebilir={guncellenebilir}
        calismaModel={calismaModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const calismalar = seciliKoro.tumCalismalar;
        calismalar.remove(calismalar.single(e => e.id === response!.id));
        calismalar.push(response!);
      });
  }

  function takvimdenGunSecildi(day: Date | undefined): void {
    if (!day)
      return;
    const calisma = genelDurumHelper.SeciliKoroModel.tumCalismalar.firstOrDefault(e => DateTimeHelper.isSameDate(e.tarihBaslangic, day));

    if (!calisma) {
      if (yonetimYetkisi)
        calismaEkle(day);
    }
    else
      calismaGuncelle(calisma, yonetimYetkisi);
  }

  const sonKayitTarihliSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.selectMany(e => e.sezonSarkiModelList.select(t => ({ sarki: e, sezonId: t.sezonModelId, tarih: t.kayitTarihi }))).where(e => e.tarih !== null);
  let tumCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar.orderByDesc(e => e.tarihBaslangic);
  const filterList = searchFilter.toLocaleLowerCase("tr-tr").split(' ').where(e => !!e);

  for (const filterItem of filterList) {
    const filteredGrupIdList = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.isim.indexOf(filterItem) > -1);
    const filteredSesGrupIdList = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.isim.indexOf(filterItem) > -1);
    const filteredAltSezonIdList = genelDurumHelper.SeciliKoroModel.tumSezonlar.selectMany(e => e.altSezonlar).where(e => e.isim.indexOf(filterItem) > -1);

    tumCalismalar = tumCalismalar.where(_calisma => _calisma.icerik.indexOf(filterItem) > -1 ||
      _calisma.icerik.toLocaleLowerCase("tr-tr").indexOf(filterItem) > -1 ||
      DateTimeToStringHelper.toMonthDayAndYearString(_calisma.tarihBaslangic, genelDurumHelper.translate).toLocaleLowerCase("tr-tr").indexOf(filterItem) > -1 ||
      filteredGrupIdList.any(t => _calisma.kadroIdList.contains(t.id)) ||
      filteredSesGrupIdList.any(t => _calisma.sesGrupIdList.contains(t.id)) ||
      (!!_calisma.altSezonModelId && altSezonKullan && filteredAltSezonIdList.any(t => _calisma.altSezonModelId === t.id)));
  }

  const today = DateTimeHelper.today();

  const ileriCalismalar = tumCalismalar.where(e => e.tarihBaslangic > today);
  const guncelCalismalar = tumCalismalar.where(e => e.tarihBaslangic <= today && e.tarihBitis >= today);
  const eskiCalismalar = tumCalismalar.where(e => e.tarihBitis < today);

  const eskiCalismaSezonIdler = eskiCalismalar.select(e => e.sezonModelId).distinct().orderByDesc(e => genelDurumHelper.SeciliKoroModel.tumSezonlar.singleOrDefault(t => t.id === e)?.isim);

  const sezonTitleGetir = (sezonId: string) => {
    const sezon = genelDurumHelper.SeciliKoroModel.tumSezonlar.single(t => t.id === sezonId);
    const sezonCalismalar = eskiCalismalar.where(e => e.sezonModelId === sezonId);

    const ilkTarih = sezonCalismalar.min(e => e.tarihBaslangic)!;
    const sonTarih = sezonCalismalar.max(e => e.tarihBaslangic)!;

    return `${sezon.isim} Çalışmaları (${DateTimeToStringHelper.toMonthAndYearString(ilkTarih, genelDurumHelper.translate)} - ${DateTimeToStringHelper.toMonthAndYearString(sonTarih, genelDurumHelper.translate)} / ${sezonCalismalar.length} Çalışma)`;
  }

  function getDayCircleColor(day: Date): string | undefined {
    const calismalar = tumCalismalar.where(e => DateTimeHelper.isSameDate(e.tarihBaslangic, day));
    const altSezonId = calismalar.firstOrDefault(e => !!e.altSezonModelId)?.altSezonModelId ?? undefined

    if (altSezonId)
      return BusinessHelper.Sezon.GetAltSezonColor(altSezonId, genelDurumHelper.SeciliKoroModel.tumSezonlar);

    return undefined;
  }

  function getIsEtkinlikDay(day: Date): boolean {
    const calismalar = tumCalismalar.where(e => DateTimeHelper.isSameDate(e.tarihBaslangic, day));

    if (calismalar.length === 0)
      return false;

    return calismalar.any(e => e.etkinlikKonserMi);
  }

  function renderDate(day: Date, size: VatanCalendarSizes): ReactNode | undefined {
    const calismalar = tumCalismalar.where(e => DateTimeHelper.isSameDate(e.tarihBaslangic, day));
    const gunSarkilari = sonKayitTarihliSarkilar.where(e => DateTimeHelper.isSameDate(e.tarih, day));

    if (calismalar.length === 0 && gunSarkilari.length === 0)
      return undefined;

    return <Box sx={{ fontSize: size !== "large" ? "8px" : "10px" }}>
      <Rows ml={1} style={{ cursor: "pointer" }}>
        {calismalar.map(item => {
          return <CsmText key={item.id} style={{
            //flex: 1,
            background: undefined,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
            {item.icerik}{item.kadroIdList.length > 0 ? null : <span style={{ fontWeight: "bold" }}> (Kadrosuz)</span>}
          </CsmText>
        })}
        {gunSarkilari.map((sarkiWithTarih, i) =>
          <CsmText key={sarkiWithTarih.sarki.id}
            hasLightBottomBorder={(i < gunSarkilari.length - 1)}
            style={{ lineHeight: "normal", color: appColors.LIGHTRED }}
            italic>
            {sarkiWithTarih.sarki.isim} kaydı son günü
          </CsmText>)}
      </Rows>
    </Box>
  }

  return (
    <MainLayout
      location={mainScreenLocations.calismaYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <ListItem>
          <Columns width="100%">
            <Cell flex><CsmTextField label={genelDurumHelper.translate("Filtre")} value={searchFilter} onChange={e => setSearchFilter(e)} eventOnKeyPress narrow></CsmTextField></Cell>
            <Cell width={120}>
              <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "-10px" }}>
                <Rows>
                  <Switch checked={takvimGoster} onChange={(e) => setTakvimGoster(e.target.checked)} />
                  <Typography sx={{ fontSize: 12, marginTop: "-5px" }}>{takvimGoster ? genelDurumHelper.translate("Takvimi Göster") : genelDurumHelper.translate("Takvimi Gösterme")}</Typography>
                </Rows>
              </FormControl>
            </Cell>
            {!altSezonKullan ? null :
              <Cell width={120}>
                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "-10px" }}>
                  <Rows>
                    <Switch checked={altSezonlariGoster} onChange={(e) => setAltSezonlariGoster(e.target.checked)} />
                    <Typography sx={{ fontSize: 12, marginTop: "-5px" }}>{takvimGoster ? genelDurumHelper.translate("Alt Sezonları Göster") : genelDurumHelper.translate("Alt Sezonları Gösterme")}</Typography>
                  </Rows>
                </FormControl>
              </Cell>
            }
            <Cell>
              <Button disabled={!yonetimYetkisi} onClick={() => calismaEkle(DateTimeHelper.today())}>
                {appIcons.CrudIcons.ekle}
                <Typography>Yeni Calisma Ekle</Typography>
              </Button>
            </Cell>
          </Columns>
        </ListItem>
        {!takvimGoster ? null :
          <ListItem>
            <VatanCalendarWithNavigation
              tatilleriGoster
              getDayCircleColor={!altSezonlariGoster ? undefined : getDayCircleColor}
              renderDate={renderDate}
              isEtkinlikDay={getIsEtkinlikDay}
              onSelectedDataChanged={takvimdenGunSecildi}
              selectedDate={undefined}
              genelDurumHelper={genelDurumHelper}
              seciliGunIcerik={undefined}
              bosGunSecilebilir
              etkinlikTakivimiKullanilsin={false} />
          </ListItem>
        }
        <CsmAccordionSimple
          title={genelDurumHelper.translate("İleri Tarihli Çalışmalar")}
          children={() => calismaRowGetir(ileriCalismalar, true)}
          emptyNote={genelDurumHelper.translate("İleri tarihli çalışma bulunmamaktadır")}
          alwaysOpen
        />
        <CsmAccordionSimple
          title={genelDurumHelper.translate("Güncel Çalışmalar")}
          children={() => calismaRowGetir(guncelCalismalar)}
          emptyNote={genelDurumHelper.translate("Güncel çalışma bulunmamaktadır")}
          alwaysOpen
        />
        {eskiCalismaSezonIdler.map(sezonId => (
          <CsmAccordionSimple
            key={sezonId}
            title={sezonTitleGetir(sezonId)}
            children={() => calismaRowGetir(eskiCalismalar.where(e => e.sezonModelId === sezonId), true)}
          />
        ))}
      </List>
    </MainLayout >
  );
}

export default CalismaYonetimScreen;

